<template>
  <modal
    id="modalTurma"
    :exibir="exibir"
    :titulo="item.nomeRelatorio"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <input-select-search
      ref="disciplina"
      :label="$t('ACADEMICO.DISCIPLINA')"
      :placeholder="$t('GERAL.TODOS')"
      :options="disciplinas"
      v-model="item.form.disciplinaId"
    />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="gerarRelatorio">
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

// Services:
import DisciplinasService from '@/common/services/academico/disciplinas.service.js';
import PainelProfessorService from '@/common/services/painel-professor/painel-professor';

// Components:
import Modal from '@/components/modal/Modal';
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: Object },
  },
  components: {
    Modal,
    InputSelectSearch,
  },
  data() {
    return {
      disciplinas: [],
    };
  },
  watch: {
    item: {
      handler() {
        this.getDisciplinasPorTurma();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getDisciplinasPorTurma() {
      if (!this.item.form.turmaId) return;
      this.$store.dispatch(START_LOADING);
      DisciplinasService.buscarPorTurmaId(this.item.form.turmaId)
        .then(({ data }) => {
          const itens = data.map((item) => ({
            text: item.descricao,
            value: item.id,
          }));
          this.disciplinas = itens;
          this.disciplinas.unshift({
            text: 'Todos',
            value: null,
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async gerarRelatorio() {
      try {
        if (!this.validarFormulario()) return;

        const objetoFormatado = { ...this.item.form };

        this.$store.dispatch(START_LOADING);

        const { data } =
          await PainelProfessorService.imprimirRelatoriosDeFechamento(
            objetoFormatado,
            this.item.rota
          );

        if (data.documento) {
          const bytes = pdf.base64ToArrayBuffer(data.documento);
          const blob = new Blob([bytes], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = data.nomeArquivo;
          link.click();
        }
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },
    fecharModal() {
      this.$emit('fechar');
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
