var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-btns',{staticClass:"mt-2 mb-n1",attrs:{"title":"Turmas"}}),_c('b-table',{ref:"selectableTable",attrs:{"fields":_vm.tabela.fields,"items":_vm.items,"empty-text":_vm.$t('GERAL.NENHUM_REGISTRO'),"head-variant":"light","select-mode":"single","responsive":"sm","selectable":"","striped":"","show-empty":"","hover":""},scopedSlots:_vm._u([{key:"cell(acoes)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('b-dropdown',{attrs:{"variant":"outline-default"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.validar(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.VALIDAR_FECHAMENTO'))+" ")]),(item.item.fechado === false)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.fechar(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.FECHAR_TURMA'))+" ")]):_vm._e(),(item.item.fechado === true)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.reabrir(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.REABRIR_TURMA'))+" ")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.liberarBoletim(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.LIBERAR_BOLETIM'))+" ")]),_c('b-dropdown-text',{staticClass:"title-dropdown",on:{"click":_vm.abrirSubDropdownRelatoriosFechamento}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIOS_FECHAMENTO'))+" "),_c('img',{class:[
                  'dropdown-arrow',
                  {
                    'dropdown-arrow-reverse':
                      _vm.exibirSubDropDown.abrirRelatoriosFechamento,
                  },
                ],attrs:{"src":require("@/assets/images/arrow.svg"),"alt":"dropdown"}})])]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){_vm.imprimirRelatorioFechamento(
                item.item,
                'quadro-analitico',
                _vm.$t('ACADEMICO.RELATORIO_QUADRO_ANALITICO')
              )}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_QUADRO_ANALITICO'))+" ")]),_c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}],staticClass:"title-dropdown-sub",attrs:{"disabled":_vm.temDisciplina(item.item)},on:{"click":function($event){_vm.imprimirRelatorioFechamento(
                item.item,
                'media-final-recuperacao',
                _vm.$t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO')
              )}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO'))+" ")]),_c('b-dropdown-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirSubDropDown.abrirRelatoriosFechamento),expression:"exibirSubDropDown.abrirRelatoriosFechamento"}]}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.imprimirBoletim(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.IMPRIMIR_BOLETINS'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.imprimirParecerDescritivo(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACADEMICO.IMPRIMIR_PARECER_DESCRITIVO'))+" ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }