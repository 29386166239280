<template>
  <b-row>
    <b-col cols="12">
      <div class="mb-4">
        <titulo-fechamento-curso-turma />

        <b-row class="d-flex align-items-start">
          <b-col class="col-12 col-md-2">
            <input-select-search
              :label="this.$t('ACADEMICO.PERIODO_LETIVO')"
              required
              ref="periodoLetivo"
              :options="opcoes.anoLetivo"
              v-model="form.anoLetivoId"
            />
          </b-col>

          <b-col class="col-12 col-md-2">
            <input-select-search
              :label="this.$t('ACADEMICO.SEGMENTACAO')"
              required
              ref="segmentacao"
              :options="opcoes.segmentacoes"
              v-model="form.segmentacaoId"
              :isDisabled="desabilitar.segmentacao"
            />
          </b-col>

          <b-col class="col-12 col-md-2">
            <input-select-search
              :label="this.$t('ACADEMICO.CURSOS')"
              required
              ref="curso"
              :options="opcoes.cursos"
              v-model="form.cursoId"
              :isDisabled="desabilitar.curso"
            />
          </b-col>
          <b-col class="col-12 col-md-2">
            <input-select-search
              :label="this.$t('ACADEMICO.TURMAS')"
              :options="opcoes.turmas"
              v-model="form.turmaId"
              :isDisabled="desabilitar.turma"
            />
          </b-col>
          <b-col cols="12" md="auto">
            <b-button
              variant="primary"
              @click="processarTurma"
              class="botao-acao-filtro w-100 my-2"
            >
              {{ this.$t('ACADEMICO.PROCESSAR_TURMA') }}
            </b-button>
          </b-col>
          <b-col cols="12" md="auto">
            <b-button
              variant="secondary"
              @click="limparFiltro"
              class="botao-acao-filtro w-100 my-2"
            >
              {{ this.$t('GERAL.LIMPAR_FILTRO') }}
            </b-button>
          </b-col>
        </b-row>

        <tabela-turma
          :items="items"
          @fechar="fechar"
          @reabrir="reabrir"
          @liberarBoletim="liberarBoletim"
          @validar="validar"
          @relatoriosFechamento="abrirModalFechamento"
          @imprimir-boletim="imprimirBoletim"
          @imprimir-parecer-descritivo="imprimirParecerDescritivo"
        />

        <modal-relatorios-fechamento
          :exibir="modais.exibirRelatoriosFechamento"
          @fechar="fecharModalFechamento"
          :item="modais.relatoriosFechamentoDados"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
// AUX & UTILS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

// SERVICES
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import TurmasService from '@/common/services/academico/turma.service';
import FechamentoCursoTurmaService from '@/common/services/fechamento-curso-turma/fechamentoCursoTurma.service';
import BoletimService from '@/common/services/boletim/boletim.service';

// COMPONENTS
import { InputSelectSearch } from '@/components/inputs';
import SegmentacaoService from '@/common/services/anoLetivo/segmentacao.service';
import TabelaTurma from './components/TabelaTurma.vue';
import ModalRelatoriosFechamento from '@/views/periodos-letivos/fechamento-curso-turma/components/ModalRelatoriosFechamento';
import TituloFechamentoCursoTurma from '@/views/periodos-letivos/fechamento-curso-turma/components/TituloFechamentoCursoTurma';
export default {
  name: 'FechamentoPeriodoCurso',
  mixins: [VerificacaoPreenchimento],
  components: {
    InputSelectSearch,
    TabelaTurma,
    ModalRelatoriosFechamento,
    TituloFechamentoCursoTurma,
  },
  data() {
    return {
      items: [],
      opcoes: {
        anoLetivo: [],
        segmentacoes: [],
        cursos: [],
        turmas: [],
      },
      form: {
        anoLetivoId: '',
        segmentacaoId: '',
        cursoId: '',
        turmaId: '',
      },
      modais: {
        exibirRelatoriosFechamento: false,
        relatoriosFechamentoDados: {
          form: {
            disciplinaId: '',
          },
        },
      },
      aviso: '',
      desabilitar: {
        turma: true,
        curso: true,
        segmentacao: true,
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
  },
  watch: {
    'form.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getSegmentacoes();
        this.getCursos(anoLetivoId);
      } else {
        this.opcoes.cursos = [];
        this.opcoes.turmas = [];
        this.opcoes.segmentacoes = [];
        this.desabilitar.curso = true;
        this.desabilitar.turma = true;
        this.desabilitar.segmentacao = true;
      }
      this.form.cursoId = null;
      this.form.turmaId = null;
      this.form.segmentacaoId = null;
    },
    'form.cursoId'(cursoId) {
      if (cursoId) {
        this.selecaoCurso(cursoId);
      } else {
        this.opcoes.turmas = [];
        this.desabilitar.turma = true;
      }
      this.form.turmaId = null;
    },
  },

  methods: {
    imprimirParecerDescritivo(item) {
      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.imprimirParecerDescritivo(item)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async imprimirBoletim(item) {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await BoletimService.imprimirBoletins({
          anoLetivoId: item.anoLetivoId,
          turmaId: item.turmaId,
        });

        if (data.documento) {
          let bytes = pdf.base64ToArrayBuffer(data.documento);

          let blob = new Blob([bytes], { type: 'application/pdf' });

          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = data.nomeArquivo;
          link.click();
        }
      } catch (error) {
        mensagem.showErrors(error);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    selecaoCurso(cursoId) {
      this.form.turmaId = '';
      this.getTurmas(cursoId);
    },

    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.ano,
            value: row.id,
          }));
          this.opcoes.anoLetivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getSegmentacoes() {
      this.$store.dispatch(START_LOADING);
      SegmentacaoService.buscarSegmentacoesPorAnoLetivoId(this.form.anoLetivoId)
        .then(({ data }) => {
          data = data.data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.form.segmentacaoId = '';
          if (data.length == 0) {
            this.opcoes.segmentacoes = [
              {
                text: this.$t('ACADEMICO.SEM_SEGMENTACAO_PARA_ANO_SELECIONADO'),
                value: undefined,
              },
            ];
          } else {
            this.opcoes.segmentacoes = data;
          }

          this.desabilitar.segmentacao = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));

          if (data.length == 0) {
            this.opcoes.cursos = [
              {
                text: this.$t('ACADEMICO.SEM_CURSOS'),
                value: undefined,
              },
            ];
          } else {
            this.opcoes.cursos = data;
          }
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurmas(cursoId) {
      if (cursoId !== '') {
        this.$store.dispatch(START_LOADING);
        TurmasService.buscarPorCursoId(cursoId)
          .then(({ data }) => {
            data = data.map((row) => ({
              ...data,
              text: row.descricao,
              value: row.id,
            }));

            this.opcoes.turmas = data;
            this.desabilitar.turma = false;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },

    processarTurma() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.listar(this.form)
        .then(({ data }) => {
          this.items = data;
          this.aviso =
            data.length == 0 ? this.$t('GERAL.SEM_DADOS_PARA_O_FILTRO') : '';
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    // FUNÇÕES
    fechar(item) {
      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.fechar(item)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
          this.processarTurma();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    reabrir(item) {
      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.reabrir(item)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
          this.processarTurma();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    liberarBoletim(item) {
      if (item.fechado !== true) {
        mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('Turma precisa estar fechada!')
        );
        return;
      }

      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.liberarBoletim({
        id: item.fechamentoCursoTurmaId,
      })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
          this.processarTurma();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    validar(item) {
      this.$store.dispatch(START_LOADING);
      FechamentoCursoTurmaService.validar(item)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    abrirModalFechamento({ item, rota, nomeRelatorio }) {
      this.modais = {
        ...this.modais,
        exibirRelatoriosFechamento: true,
        relatoriosFechamentoDados: {
          form: item,
          rota: rota,
          nomeRelatorio: nomeRelatorio,
        },
      };
    },

    fecharModalFechamento() {
      this.modais = {
        ...this.modais,
        exibirRelatoriosFechamento: false,
        relatoriosFechamentoDados: {
          form: {
            disciplinaId: '',
          },
        },
      };
    },
    // FILTRO
    limparFiltro() {
      this.form = {
        anoLetivoId: '',
        segmentacaoId: '',
        cursoId: '',
        turmaId: '',
      };
      this.items = [];
    },
  },
};
</script>
