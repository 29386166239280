<template>
  <div>
    <titulo-btns class="mt-2 mb-n1" title="Turmas"></titulo-btns>
    <b-table
      :fields="tabela.fields"
      :items="items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      select-mode="single"
      ref="selectableTable"
      responsive="sm"
      selectable
      striped
      show-empty
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="validar(item.item)">
              {{ $t('ACADEMICO.VALIDAR_FECHAMENTO') }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.item.fechado === false"
              @click="fechar(item.item)"
            >
              {{ $t('ACADEMICO.FECHAR_TURMA') }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.item.fechado === true"
              @click="reabrir(item.item)"
            >
              {{ $t('ACADEMICO.REABRIR_TURMA') }}
            </b-dropdown-item>

            <b-dropdown-item @click="liberarBoletim(item.item)">
              {{ $t('ACADEMICO.LIBERAR_BOLETIM') }}
            </b-dropdown-item>

            <!-- RELATÓRIOS FECHAMENTO -->
            <!------ TITULO -->
            <b-dropdown-text
              class="title-dropdown"
              @click="abrirSubDropdownRelatoriosFechamento"
            >
              <div class="d-flex align-items-center justify-content-between">
                {{ $t('ACADEMICO.RELATORIOS_FECHAMENTO') }}
                <img
                  :class="[
                    'dropdown-arrow',
                    {
                      'dropdown-arrow-reverse':
                        exibirSubDropDown.abrirRelatoriosFechamento,
                    },
                  ]"
                  src="@/assets/images/arrow.svg"
                  alt="dropdown"
                />
              </div>
            </b-dropdown-text>

            <!------ QUADRO ANALÍTICO -->
            <b-dropdown-item
              class="title-dropdown-sub"
              :disabled="temDisciplina(item.item)"
              v-show="exibirSubDropDown.abrirRelatoriosFechamento"
              @click="
                imprimirRelatorioFechamento(
                  item.item,
                  'quadro-analitico',
                  $t('ACADEMICO.RELATORIO_QUADRO_ANALITICO')
                )
              "
            >
              {{ $t('ACADEMICO.RELATORIO_QUADRO_ANALITICO') }}
            </b-dropdown-item>

            <!------ MÉDIA FINAL E RECUPERAÇÃO -->
            <b-dropdown-item
              class="title-dropdown-sub"
              :disabled="temDisciplina(item.item)"
              v-show="exibirSubDropDown.abrirRelatoriosFechamento"
              @click="
                imprimirRelatorioFechamento(
                  item.item,
                  'media-final-recuperacao',
                  $t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO')
                )
              "
            >
              {{ $t('ACADEMICO.RELATORIO_MEDIA_FINAL_RECUPERACAO') }}
            </b-dropdown-item>

            <b-dropdown-divider
              v-show="exibirSubDropDown.abrirRelatoriosFechamento"
            />

            <!-- Fim relatorios de fechamento -->
            <b-dropdown-item @click="imprimirBoletim(item.item)">
              {{ $t('ACADEMICO.IMPRIMIR_BOLETINS') }}
            </b-dropdown-item>

            <b-dropdown-item @click="imprimirParecerDescritivo(item.item)">
              {{ $t('ACADEMICO.IMPRIMIR_PARECER_DESCRITIVO') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import TituloBtns from '@/components/headers/TituloBtns.vue';
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaTurma',
  components: {
    TituloBtns,
  },
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'turmaDescricao',
            label: 'Turma',
            sortable: true,
          },
          {
            key: 'fechado',
            label: 'Status',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanFechadoAberto(value);
            },
          },
          {
            key: 'dataFechamento',
            label: 'Dt. fechamento',
            sortable: true,
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
              return '-';
            },
          },
          {
            key: 'dataReAbertura',
            label: 'Dt. reabertura',
            sortable: true,
            formatter: (value) => {
              if (value) return helpers.formatarDataBr(value);
              return '-';
            },
          },
          {
            key: 'boletimLiberado',
            label: 'Boletim lib.',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarBooleanSimNao(value);
            },
          },
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          },
        ],
      },
      exibirSubDropDown: {
        abrirRelatoriosFechamento: false,
      },
    };
  },
  methods: {
    fechar(item) {
      this.$emit('fechar', item);
    },
    reabrir(item) {
      this.$emit('reabrir', item);
    },
    liberarBoletim(item) {
      this.$emit('liberarBoletim', item);
    },
    validar(item) {
      this.$emit('validar', item);
    },

    imprimirRelatorioFechamento(item, rota, nomeRelatorio) {
      this.$emit('relatoriosFechamento', {
        item,
        rota,
        nomeRelatorio,
      });
    },

    imprimirBoletim(item) {
      this.$emit('imprimir-boletim', item);
    },
    imprimirParecerDescritivo(item) {
      this.$emit('imprimir-parecer-descritivo', item);
    },
    // opções
    abrirSubDropdownRelatoriosFechamento() {
      this.exibirSubDropDown.abrirRelatoriosFechamento =
        !this.exibirSubDropDown.abrirRelatoriosFechamento;
    },

    temDisciplina(item) {
      return item.disciplinaId == '00000000-0000-0000-0000-000000000000'
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.title-dropdown,
.title-dropdown-sub {
  cursor: pointer;
  font-weight: bold;
  color: #272b34;
}

.title-dropdown-sub a {
  font-size: 12px;
  padding-left: 30px;
}

.title-dropdown:hover,
.title-dropdown:focus {
  background-color: #f8f9fa;
}

.dropdown-arrow {
  max-width: 8px;
}

.dropdown-arrow-reverse {
  transform: rotate(180deg);
}
</style>
