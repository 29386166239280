import ApiService from '@/common/api/api.service';

let apiBasePath = 'FechamentoCursoTurma';
const FechamentoCursoTurmaService = {
  // FUNÇÕES CRUD:

  async listar(filtro) {
    let { data } = await ApiService.get(`${apiBasePath}`, {
      params: {
        turmaId: filtro.turmaId,
        cursoId: filtro.cursoId,
        segmentacaoId: filtro.segmentacaoId,
        anoLetivoId: filtro.anoLetivoId,
      },
    });
    return data;
  },
  async fechar(form) {
    let { data } = await ApiService.put(`${apiBasePath}/fechar`, form);
    return data;
  },
  async reabrir(form) {
    let { data } = await ApiService.put(`${apiBasePath}/reabrir`, form);
    return data;
  },
  async validar(form) {
    let { data } = await ApiService.get(`${apiBasePath}/validar`, {
      params: {
        turmaId: form.turmaId,
        cursoId: form.cursoId,
        segmentacaoId: form.segmentacaoId,
        anoLetivoId: form.anoLetivoId,
        FechamentoCursoTurmaId: form.FechamentoCursoTurmaId,
      },
    });
    return data;
  },
  async liberarBoletim({ id }) {
    let { data } = await ApiService.put(`${apiBasePath}/liberar-portal/${id}`);
    return data;
  },
  async imprimirParecerDescritivo(form) {
    let { data } = await ApiService.get(`${apiBasePath}/imprimir-parecer-descritivo`, {
      params: {
        TurmaId: form.turmaId,
        SegmentacaoId: form.segmentacaoId,
        CursoId:form.cursoId
      },
    });
    return data;
  },
};

export default FechamentoCursoTurmaService;
